<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link v-if="$can('sales_period.create')" to="/salestargetcommission/sales-periods/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('sales_periods.add_sales_period') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="employee_id">{{ $t('sales_periods.employee') }}</label>
              <!-- <select name="" id="employee_id" v-model="filters.employee_id" class="custom-select" >
                  <option v-for="row in employees" :value="row.id" :key="row.id">
                      {{ row.full_name }}
                  </option>
              </select> -->
              <multiselect v-model="employee"
                           :placeholder="$t('sales_periods.employee')"
                           label="full_name"
                           track-by="id"
                           :options="employees"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @search-change="getEmployees($event)">
              </multiselect>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('sales_periods.sales_commission') }}</label>
              <!-- <select name="" id="commission_period" v-model="filters.commision_sales_id" class="custom-select" >
                  <option v-for="row in commision_sales_list" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select> -->
              <multiselect v-model="sales_commission"
                           :placeholder="$t('sales_periods.sales_commission')"
                           label="name"
                           track-by="id"
                           :options="commision_sales_list"
                           :multiple="false"
                           :taggable="true"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('sales_periods.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('sales_periods.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>


            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>


            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('sales_periods.sales_periods') }}
            <span class="text-muted pt-2 font-size-sm d-block"
            >{{ $t(('sales_periods.sales_periods_section')) }}</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button-->


          <!--end::Button-->
        </div>
      </div>

      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="status" slot-scope="props">

            <b-form-checkbox v-if="$can('sales_period.change_status')"
                             size="lg" @change="changeStatus(props.row.id, props.row.status)"
                             v-model="props.row.status"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>

            <b-form-checkbox v-else
                             size="lg" :disabled="true"
                             v-model="props.row.status"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
          </template>

          <template slot="actions" slot-scope="props">
            <v-btn icon color="pink" v-if="$can('sales_period.update')" :to="`/salestargetcommission/sales-periods/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
            </v-btn>

            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('sales_period.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>

            <v-btn icon color="pink" :to="`/salestargetcommission/sales-periods/view/${props.row.id}`">
              <v-icon small v-b-tooltip.hover :title="$t('view')" class="mr-2">mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-sales-periods",

  data() {
    return {
      mainRoute: 'salestargetcommission/sales-periods',
      routeChangeStatus: 'salestargetcommission/sales-period/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        employee_id: null,
        from_date: null,
        to_date: null,
        commision_sales_id: null,
        status: null,
      },
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      columns: ['commision_sales_name', 'from_date', 'to_date', 'total_commission', 'status', 'actions'],
      data: [],
      commision_sales_list: [],

      employees: [],
      employee: null,
      sales_commission: null,

    }
  },
  watch: {
    employee: function (val) {
      if (val) {
        this.filters.employee_id = val.id;
      } else {
        this.filters.employee_id = null;
      }
    },
    sales_commission: function (val) {
      if (val) {
        this.filters.commision_sales_id = val.id;
      } else {
        this.filters.commision_sales_id = null;
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          commision_sales_name: that.$t('sales_periods.sales_commission'),
          from_date: that.$t('sales_periods.from_date'),
          to_date: that.$t('sales_periods.to_date'),
          total_commission: that.$t('sales_periods.total_commission'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_periods")}]);
    this.getCommissionSales();
    // this.getEmployees();
  },
  methods: {


    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.employee_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.commision_sales_id = null;
      this.filters.status = null;
      this.employee = null;
      this.sales_commission = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    getEmployees(filter) {
      if(filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/employees", {params:{name: filter}}).then((response) => {
          this.employees = response.data.data;
        });
    },
    getCommissionSales() {
      ApiService.get(this.mainRouteDependency + "/commision_sales").then((response) => {
        this.commision_sales_list = response.data.data;
      });
    },
    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        status: (status ? '1' : '0'),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },


  },
};
</script>
